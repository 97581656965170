/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
@import "@ionic/angular/css/palettes/dark.system.css";


@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

// ion-button button:not(.button-round) {
//     background-color: blue; /* Styles only the regular button */
//     color: white;
//     border-radius: 12px!important;
//     --border-radius: 12px!important;
//   }

  // ion button not with attribute shape=round
ion-button:not([shape=round]) {
    border-radius: 0px!important;
    --border-radius: 0px!important;
  }

.basic-input {
    ion-input {
        border: 1px solid rgb(51, 51, 51);
    }
}

.gray-bg {
    --background: #f4f5f8;
}


.rounded-skin {
    border-radius: 4px;
    padding: 0px;
}

.data-table-container {
    width: 100%;
    //overflow-y: auto;
}

.full-height {
    height: 100% !important;
}

.full-width {
    width: 100% !important;
}

.data-table {
    border-collapse: collapse;
    width: 100%;
    overflow-y: auto;

    thead {
        tr {
            th:last-child {
                border-right: 0px;
            }

            ion-checkbox {
                --background-checked: #ffffff;
                --checkmark-color: #20215b;
                --border-color: #fff;
                --border-color-checked: #ffffff;
            }

            >th {
                position: sticky !important;
                top: 0 !important;
                background: #20215b;
                text-transform: uppercase;
                z-index: 1000;
                //}
                //> th {
                text-align: left;
                padding: 0 6px;
                text-align: left;
                font: normal normal normal 13px/22px Lato;
                letter-spacing: 0px;
                color: #ffffff;
                border-right: 2px solid #787892;
            }
        }
    }

    .sticky-tr {
        background: #fff;
        z-index: 1000;
    }

    .sticky-tr th {
        position: sticky;
        top: 66px;
        background: #fff;
        z-index: 1000;
    }

    tr {
        height: 55px;
        //border-bottom: 1px solid #ddd;
    }

    tr:nth-child(even) {
        background-color: #f5f8f9;
    }

    td {
        text-align: left;
        padding: 0 6px;
        font: normal normal normal 14px/17px sans-serif;
        letter-spacing: 0px;
        color: #232424;
    }

    ion-checkbox {
        --size: 21px;
        --border-color: #cecece;
        vertical-align: middle;
        --border-width: 1px;
        margin-right: 4px;
    }

    .line-input ion-item {
        --min-height: 31px;
        font-size: 12px;
        --padding-start: 4px;
        --padding-end: 4px;
        --background: #f3f3f3;
        box-shadow: 0px 1.1px 2px 0px #ecececd6;
        --border-color: transparent !important;
        border: 1px solid #f3f3f3;

        ion-icon {
            font-size: 17px;
            opacity: 0.7;
        }
    }

    .line-input ion-item ion-datetime {
        --padding-bottom: 3px;
        --padding-top: 4px;
    }

    .line-input ion-select {
        min-width: 98px;
    }

    .check-td {
        padding-left: 46px;
        position: relative;

        ion-checkbox {
            position: absolute;
            left: 18px;
        }
    }
}

//css from main app start here

/* button  styling */
.cs-btn {
    --padding-start: 45px;
    --padding-end: 45px;
    --padding-top: 21px;
    --padding-bottom: 21px;
    font-weight: 600;
}

.green-btn {
    --box-shadow: 0px 0px 0px 0px;
    --background: #1dc00c;
}

.form-lg-btn {
    --padding-bottom: 22px;
    --padding-start: 35px;
    --padding-end: 35px;
    height: 47px;
    --padding-top: 22px;
}

.transparent-btn {
    --background: transparent;
    color: black;
}

.blue-btn {
    --background: #0c3aa5;
}

.blue-btn-ot {
    --border-color: #0c3aa5;
    color: #0c3aa5;
}

/* slider  styling */
.slider-control .swiper-pagination-bullet {
    width: 35px !important;
    height: 3px !important;
    border-radius: 4px !important;
}

.slider-control .swiper-pagination-bullets {
    text-align: left;
}

/* column styling */
.center-col {
    margin: 0px auto;
    padding: 0;
}

/* form page common styling */

// .form-cm-padding {
//     .line-input {
//         padding: 0px 15px;
//     }
//     font-size: 14px;
// }

.line-head {
    padding: 10px 15px;
    color: #385389;
    font-weight: 500;
    font-size: 15px;

    strong {
        border: 2px solid #385389;
        width: 25px;
        height: 25px;
        line-height: 21px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
    }

    span {
        font-weight: 600;
        display: inline-block;
        padding-left: 5px;
    }
}

.xl-line-head {
    padding: 10px 10px;
    font-size: 15px;
    color: #0c50d8;
}

/* custom form starts here */
th .line-input ion-item {
    border-radius: 0;
}

.line-label ion-item {
    --min-height: 31px;
    font-size: 13px;
    --padding-start: 4px;
    --padding-end: 4px;
    // --background: #f3f3f3;
    // box-shadow: 0px 1.1px 2px 0px #ecececd6;
    --border-color: transparent !important;
    // border: 1px solid #f3f3f3;
    ion-label{
        text-transform: uppercase;
    }

    ion-icon {
        font-size: 17px;
        opacity: 0.7;
    }
}

.line-input {
    padding-bottom: 14px;
    position: relative;

    >ion-label {
        font-size: 12.5px;
        line-height: 12.5px;
        // padding: 1px 0px;
        display: inline-block;
        font-weight: 500;
        color: #404650;
        text-transform: uppercase;
    }

    >ion-item,
    date-picker>ion-item {
        // border-radius: 8px;
        // ion-label {
        //     display: none !important;
        // }
        font-size: 13px;
        --border-color: transparent !important;
        margin-bottom: 0;

        // --highlight-height: 0;
        // border: 1px solid #c3c7d3;
        // &::part(native) {
        //     border: 1px solid #ddd;
        //     height: 35px;
        // }
        &:not(.text-area-item) {
            --min-height: 37px;

            &::part(native) {
                border: 1px solid #ddd;
                height: 35px;
            }
        }

        &.text-area-item {
            --min-height: 37px;

            &::part(native) {
                border: 1px solid #ddd;
                height: auto;
            }
        }

        // box-shadow: 0px 1.1px 2px 0px #c5d6ead6;

        // &.item-has-focus {
        //     border: 1px solid var(--ion-color-primary);
        // }

        ion-input {
            --padding-top: 5px;
            --padding-bottom: 5px;
        }
    }

    * {
        // --highlight-height: 0px !important;
    }

    .add-more {
        position: absolute;
        right: 29px;
        top: 51px;
        font-size: 28px;
        z-index: 100;
        color: #939cae;
    }

    /* Set the placeholder color and opacity */
    ion-select::part(placeholder) {
        position: absolute;
        left: 0px;
        opacity: 1;
    }

    /* Set the text color */
    ion-select::part(text) {
        position: absolute;
        left: 0px;
    }

    /* Set the icon color and opacity */
    ion-select::part(icon) {
        position: absolute;
        right: 11px;
    }
}

.line-input {
    input {
        border: none;
        height: 100%;
        width: 100%;
    }

    input:focus {
        border: none;
        outline: none;
    }
}

.line-input ion-item,
.line-input ion-select,
.line-input mat-select {
    min-width: 98px;
    font-size: 13px;
}

ion-item.ion-invalid.ion-touched {
    border: 1px solid var(--ion-color-danger) !important;
    --min-height: 33px;
}

input.error {
    border: 1px solid var(--ion-color-danger) !important;
}

.error-text {
    color: red;
}

input.error {
    border: none;
    border-bottom: 1px solid var(--ion-color-danger);
}
input.error{
    border: 1px solid var(--ion-color-danger) !important;
}

ion-item.error {
    &::part(native) {
        border: 1px solid var(--ion-color-danger) !important;
    }
    // border: 1px solid red !important;
}

.input-error-msg  {
    padding: 1px 0;
    font-size: 11px;
    color: var(--ion-color-danger);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}
.error-msg  {
    // padding: 1px 0;
    // font-size: 11px;
    color: var(--ion-color-danger);
    // position: absolute;
    // bottom: -12px;
}

form {
    ion-col {
        padding: 1px 8px;
    }
}

.dt-xs-input {
    ion-icon {
        font-size: 17px;
    }

    ion-item {
        --min-height: 34px;
        font-size: 12px;
    }

    ion-datetime {
        padding: 4px;
        min-width: 67px;
    }
}

.left-dec {
    margin-left: -1px;
}

/* common css */
.widget-skin {
    background: #fff;
    box-shadow: 0px 0px 6px 1px #a8cfe242;
    padding: 12px 12px 0 12px;
    margin: 12px 12px 0 12px;
    // margin: 12px;
}

.xl-padding-btn {
    padding: 40px 10px 20px;
}

.table-section-bg {
    // padding: 8px 12px;
    background: #fff;
    min-height: 100%;
}

@media screen and (max-width: 480px) {
    .table-section-bg {
        padding: 0;
    }
}

//    table structure
ion-button,
ion-segment-button {
    // --border-radius: 0;
}

.table-main {
    overflow-x: auto;
}

.data-table-container {
    width: 100%;
    // min-width: 800px;
    // overflow-x: auto;
}

.data-table {
    border-collapse: collapse;
    width: 100%;
    background: #ffffff;

    thead {
        tr {
            height: 45px;

            th:last-child {
                border-right: 0px;
            }

            ion-checkbox {
                --background-checked: #ffffff;
                --checkmark-color: #20215b;
                --border-color: #fff;
                --border-color-checked: #ffffff;
            }

            >th {
                // position: sticky !important;
                // top: 0 !important;
                // background: #20215b;
                background: #363636;
                // background: var(--ion-color-dark);
                text-transform: uppercase;
                z-index: 1000;
                //}
                //> th {
                text-align: center;
                padding: 0 6px;
                // text-align: left;
                font: normal normal normal 13px/18px sans-serif;
                letter-spacing: 0px;
                color: #ffffff;
                border-right: 2px solid #787892;
                min-width: 80px;
                // width: 80px;
            }

            .action-col {
                width: 180px;
                min-width: 160px;
            }
        }
    }

    .sub-head {
        height: 30px;

        th {
            border: 1px solid #ccc;
            min-width: 120px;
            font-size: 14px;
            background-color: white;
            color: #666;
            height: 20px;

            &:first-child {
                min-width: 60px;
            }
        }
    }

    .sticky-tr {
        background: #fff;
        z-index: 1000;
    }

    .sticky-tr th {
        // position: sticky;
        top: 66px;
        background: #fff;
        z-index: 1000;
    }

    tr {
        height: 45px;
        //border-bottom: 1px solid #ddd;
    }

    tr:nth-child(even) {
        background-color: #f5f8f9;
    }

    td {
        text-align: left;
        padding: 0 6px;
        font: normal normal normal 14px/17px sans-serif;
        letter-spacing: 0px;
        color: #232424;
        ion-chip{
            padding:  3px 9px;
        }

        ion-buttons {
            height: 45px;
        }
    }

    th:first-child,
    td:first-child {
        text-align: center;
        min-width: 60px;
        width: 60px;
        min-width: 60px;
    }

    ion-checkbox {
        --size: 21px;
        --border-color: #cecece;
        vertical-align: middle;
        --border-width: 1px;
        margin-right: 4px;
    }

    .line-input>ion-item {
        --min-height: 31px;
        font-size: 12px;
        --padding-start: 4px;
        --padding-end: 4px;
        --inner-padding-end: 0;
        --background: #f3f3f3;
        box-shadow: 0px 1.1px 2px 0px #ecececd6;
        --border-color: transparent !important;
        border: 1px solid #f3f3f3;

        ion-icon {
            font-size: 17px;
            opacity: 0.7;
        }
    }

    .line-input>ion-item ion-datetime {
        --padding-bottom: 3px;
        --padding-top: 4px;
    }

    .line-input>ion-select,
    .line-input>mat-select {
        min-width: 98px;
        font-size: 13px;
    }

    .check-td {
        padding-left: 46px;
        position: relative;

        ion-checkbox {
            position: absolute;
            left: 18px;
        }
    }
}

.paginator-footer-bar {
    padding: 5px 50px;

    mat-paginator {
        font-size: 16px !important;
    }
}

.tb-footer-bar {
    margin: 40px 20px 20px;
    display: flex;
    justify-content: space-between;

    .tb-buttn-action {
        display: flex;

        .line-input>ion-item {
            border-radius: 25px;
            min-width: 140px;
            margin: 4px 10px;
        }
    }

    .pagination {
        display: flex;

        ul {
            display: flex;
            list-style: none;
            margin: 0px;
            padding: 0px;

            li {
                border: 1px solid #e6eaee;
                height: 40px;
                width: 40px;
                border-radius: 4px;
                margin: 0px 5px;
                line-height: 40px;
                text-align: center;
                box-shadow: 0px 1px 1px 0px #e0e4e8;

                ion-icon {
                    padding-top: 11px;
                    cursor: pointer;
                }

                ion-icon:nth-child(2) {
                    margin-left: -9px;
                }
            }

            .act-count {
                border: 0px;
                width: initial;
                line-height: 42px;
                box-shadow: 0px 1px 1px 0px #ffffff;
            }
        }

        .disable {
            background: #fbfdfd;
            color: #c0c9d3;
        }

        .active {
            background: #0c3aa5;
            color: #fff;
            border-color: #0c3aa5;
        }

        span {
            padding: 9px;
        }
    }
}

.action-bar-btn ion-icon[name="create-outline"] {
    font-size: 21px;
    color: #757575;
    padding: 4px;
}

.action-bar-btn ion-icon[name="trash-outline"] {
    font-size: 21px;
    color: #d20808;
    padding: 4px;
}

.action-bar-btn {
    span {
        font-size: 11px;
        display: inline-block;
        padding: 3px 19px 3px 10px;
        border-radius: 15px;
        background: #cdf5c8;
        color: #129307;
        position: relative;
        margin-bottom: 6px;
        vertical-align: top;

        ion-icon {
            position: absolute;
            margin-top: 3px;
            right: 5px;
        }
    }
}

tr:nth-child(even) {
    background-color: #f8f7f7;
}

.big-box {
    margin: 5px;
}

.check {
    font-size: 27px;
    color: green;
}

.close1 {
    font-size: 27px;
    color: rgb(224, 20, 65);
}

.success {
    color: var(--success);
}

.warning {
    color: var(--warning);
}

.warning-icon {
    font-size: 27px;
    color: var(--warning);
}

.danger {
    color: var(--danger);
}

ion-icon {
    // svg {
    //pointer-events: none !important;
    // }
}

// ::ng-deep {
// }

.print-btn {
    ion-icon {
        font-size: 25px;
        vertical-align: middle;
    }

    margin-bottom: 5px;
}

/** Document Status Modal **/
.doc-status-modal {
    --height: 250px;
    border-radius: 15px;
}

.otp-modal {
    --height: 50%;
    --min-height: 360px;
    --border-radius: 0;
    box-shadow: 0 2px 8px #0000006e;
}


.print-barcode-modal {
    --height: 350px;
    --border-radius: 0;
    box-shadow: 0 2px 8px #0000006e;
}


.city-planning-modal {
    --height: 90%;
    --width: 80%;
    --border-radius: 0;
    box-shadow: 0 2px 8px #0000006e;
}

.city-planning-modal-small {
    --height: 30%;
    --width: 20%;
    --border-radius: 0;
    --box-shadow: 0 2px 8px #0000006e;
}

.add-product-supplier-modal {
    --height: 80%;
    --width: 80%;
    --min-width: 340px;
    --max-width: 1000px;
    --border-radius: 0;
    //box-shadow: 0 2px 8px #0000006e;
}

.select-product-supplier-modal {
    --height: 70%;
    --width: 60%;
    --border-radius: 0;
    --box-shadow: 0 2px 8px #0000006e;
}

.select-product-modal {
    --height: 70%;
    --width: 70%;
    --min-width: 320px;
    --max-width: 900px;
    --border-radius: 0;
    --box-shadow: 0 2px 8px #0000006e;
}

.generate-receiving-order-modal {
    --height: 90%;
    --width: 80%;
    --min-width: 320px;
    // --max-width: 900px;
    --border-radius: 0;
    --box-shadow: 0 2px 8px #0000006e;
}


.ellipsis {
    width: 170px !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    position: relative;
}

.wrap {
    width: 110px !important;
    white-space: wrap;
}

.wrap-address {
    width: 150px !important;
    white-space: wrap;
}

.wrap-less {
    width: 80px !important;
    white-space: wrap;
}

ionic-context-menu-item ion-item {
    --background: #04194a !important;
    --color: #fff !important;

    ion-icon {
        color: #fff !important;
    }
}

ionic-context-menu-item>ion-item>ion-icon {
    --color: #fff !important;
}

.breadcrumb ion-icon {
    font-size: 20px;
}

.breadcrumb li {
    font-size: 18px !important;
    vertical-align: middle !important;
    margin-top: 3px !important;
}

.breadcrumb {
    background-color: #f7f6f6;
    margin-left: 15px !important;
}

.chart-modal {
    --width: 90%;
    --height: 95%;
    --border-radius: 10px;
}

.edit-rating {
    --width: 400px;
    --height: 400px;
    --border-radius: 10px;
}

.upload-popover .popover-content {
    width: 400px;
}

@media screen and (max-width: 600px) {
    .chart-modal {
        --width: 100%;
        --height: 95%;
        --border-radius: 0;
        position: absolute;
        bottom: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

.edit-drug-modal {
    --width: 95%;
    --height: 95%;
    border-radius: 10px;
}

.select-product-modal {
    --width: 70%;
    --height: 90%;
    --min-width: 320px;
    --max-width: 900px;
}

.add-product-modal {
    --width: 80%;
    --height: 85%;
}

.add-supplier-modal {
    --width: 80%;
    --height: 85%;
}

.add-product-category-modal {
    --width: 40%;
    --min-width: 300px;
    --max-width: 400px;

    --height: 30%;
    --min-height: 280px;
    --max-height: 290px;
}

.purchase-order-print-modal {
    --width: 90%;
    --height: 90%;
    --min-width: 320px;
    --max-width: 1200px;
}

.receiving-order-print-modal {
    --width: 90%;
    --height: 90%;
    --min-width: 320px;
    --max-width: 1200px;
}

.send-invitation {
    --width: 600px;
    --height: 300px;
    --border-radius: 10px;
}

.driver-password {
    --width: 600px;
    --height: 250px;
    --border-radius: 10px;
}

.edit-seal {
    --width: 500px;
    --height: 450px;
    --border-radius: 10px;
}

:root {
    .popover-content {
        // left: 0 !important;
        // width: 100%;
    }
}

.alert-err h2 {
    color: #ff4757 !important;
}

.alert-succ h2 {
    color: #009432 !important;
}

.alert-w-500 .alert-wrapper {
    width: 500px;
    max-width: 500px;
}

:disabled {
    opacity: 0.8;
    color: #000 !important;
}

.mat-input-hint-bottom {
    position: absolute;
    left: 0;
    border: 0;
    bottom: 11px;
    padding: 0 12px;
}

table {
    width: 100%;
}

.hand {
    cursor: pointer;
}

.img-viewer {
    --width: 90%;
    --height: 90%;
    border-radius: 10px;
}

.sf-filter-mat-option {
    font-size: 14px;
    border-bottom: 1px solid #ddd;
}

// .table-box {
//     background: #ffffff;
//     box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1254901961);
//     border-radius: 8px;
// }
ion-segment {
    --height: 40px;
    margin-bottom: 0;
    width: min-content;
    ion-segment-button {
        width: min-content;
        --height: 40px;
        margin-right: 5px;
        --indicator-color: var(--ion-color-secondary-tint);
        --border-top-left-radius: 5px;
        --border-top-right-radius: 5px;
        --background: #fafafa50;
        --color: #090909;
        --background-checked: var(--ion-color-secondary);
        --background-focused: var(--ion-color-secondary-shade);
        --color-checked: #ffffff;
        width: min-content;
    }
}

//fixed the backdrop not showing issue in modal inside modal
ion-modal {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
}

//disable ion input validation highlight
// ion-item {
//     --highlight-height: 0px !important;
//     --highlight-background:white;
// }

.pad-10 {
    padding: 10px;
}

.line-head {
    padding-left: 0px;

    span {
        padding-left: 0px;
    }
}

ion-content {
    --background: #e8ecee;
}

ion-modal ion-content {
    --background: #fff;
}

ion-item.select-box ion-icon {
    width: 13px;
    height: 12px;
}

//css from main app end here

.hand {
    cursor: pointer;
}

contact-info-form {
    width: 100%;
}

.vendor-popup {
    --width: 900px;
}

.main-card {
    min-height: 100%;
    overflow: visible;
    margin: 0;
}

.sup-box {
    background: #f5f8f9;
    padding: 2px 6px;
}

.ml-10 {
    margin-left: 10px;
}

.f-20 {
    font-size: 20px !important;
}

.print-button {
    background: var(--ion-color-primary);
    padding: 15px 30px;
    color: white;
}

.view-product-sup {
    --width: 80%;
    --height: 80%;
}

.add-edit-service {
    --height: 650px
}

// Report Header
ion-select label,
ion-select .select-wrapper,
::ng-deep ion-select label,
::ng-deep ion-select .select-wrapper,
label,
.select-wrapper,
ion-select::part(label),
ion-select::part(.select-wrapper) {
    background-color: red !important;
    border-right: 1px solid #bbb;
    padding: 7px;
    display: inline-block;
    color: #444;
    background-color: #eee;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

}

::ng-deep ion-select {

    .select-wrapper {
        background-color: red !important;
        border-right: 1px solid #bbb;
        padding: 7px;
        display: inline-block;
        color: #444;
        background-color: #eee;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

    }
}